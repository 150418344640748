import React from "react";
import { withTranslation, TFunction } from "react-i18next";
import styled, { createGlobalStyle } from "styled-components";
import Container from "../../common/Container";
import { Link } from "react-router-dom";
const GlobalFontStyle = createGlobalStyle`
    
  @font-face {
    font-family: 'Motiva Sans Light';
    src: url('/fonts/Motiva-Sans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const FooterWrapper = styled.footer`
    background-color: #ffffff; /* 改为白色背景 */
    padding: 0 0;
    margin-top: 66px;
    width: 100%;
    font-family: 'Motiva Sans Light', Arial, sans-serif;
    border-top: 1px solid rgb(200, 200, 200); /* 更深的灰色分界线 */
`;
// 添加 Logo 的容器样式
export const LogoImage = styled.img`
    width: 50px; /* 设置 logo 的宽度 */
    height: auto; /* 自动调整高度以保持宽高比 */
    margin-right: 10px; /* 给 logo 和文字之间留出间距 */
`;

// 原有的 LogoContainer 样式
export const LogoContainer = styled(Link)`
    display: flex;
    align-items: center; /* 确保 logo 和文字垂直居中对齐 */
    margin-bottom: 10px;
    @media only screen and (max-width: 890px) {
        margin-left: 15px;
    }
`;

// 原有的 CustomSpan 样式
export const CustomSpan = styled("span")`
    width: 139px;
    height: 63px;
    overflow-wrap: break-word;
    color: #FF5400;
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    
    text-align: left;
    white-space: nowrap;
    line-height: 63px;
`;

const FooterContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0rem;
    min-height: 50px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding: 0.1rem 1rem;
        text-align: center;
    }
`;

const FooterSection = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        margin-bottom: 0.1rem; /* 调整移动端的下边距 */
        &:last-child {
            margin-bottom: 0.1rem; /* 如果是最后一个元素，去掉下边距 */
        }
        justify-content: center;
        width: 100%;
    }
`;

const FooterText = styled.span`
    font-size: 0.75rem;
    color: #808080; /* 改成灰色 */
    margin-right: 1rem;
    &:last-child {
        margin-right: 0;
    }
    
`;
const FooterText2Container = styled.div`
    margin-bottom: 25px;
    @media only screen and (max-width: 890px) {
        margin-left: 15px; /* 小屏幕时也有左边距 */
    }
`;
const FooterText2 = styled.span`
    font-size: 0.75rem;
    color: #808080; /* 改成灰色 */
    margin-right: 1rem;
    &:last-child {
        margin-right: 0;
    }

`;
const FooterLink = styled.a`
    color: #808080; /* 改成灰色 */
    text-decoration: none;
    transition: color 0.3s ease;
    
    font-size: 0.75rem;
    &:hover {
        color: #FF5400;
    }
    @media screen and (max-width: 768px) {
        font-size: 0.75rem;
    }
`;

const FooterDivider = styled.span`
    color: #808080; /* 改成灰色 */
    margin: 0 0.5rem;
`;

const Footer = ({ t }: { t: TFunction }) => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <Container>
                <GlobalFontStyle />

                    <FooterWrapper>
                        <LogoContainer to="/" aria-label="homepage">
                            {/* 替换为你的 logo 图片路径 */}
                            <CustomSpan>sgpr.ai</CustomSpan>
                        </LogoContainer>
                        <FooterText2Container>
                            <FooterText2>An innovative AI-powered platform dedicated to Singapore PR application services.
                            </FooterText2>
                        </FooterText2Container>
                        <FooterText2Container>
                            <FooterLink href="mailto:contact@sgpr.ai">{t("Contact Us")}</FooterLink>
                            <FooterDivider>|</FooterDivider>
                            <FooterLink href="/privacy">{t("Privacy Policy")}</FooterLink>
                            <FooterDivider>|</FooterDivider>
                            <FooterLink href="/terms">{t("Terms of Service")}</FooterLink>
                        </FooterText2Container>
                        <FooterText2Container>
                            <FooterText>© {currentYear} sgpr.ai. All rights reserved.</FooterText>
                        </FooterText2Container>

                    </FooterWrapper>
            </Container>
        </>
    );
};

export default withTranslation()(Footer);
